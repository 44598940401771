import '../css/Home.css';
import bigLogo from '../images/scrump-tech.png'

function Home() {

  return (
    <>
        <img src={bigLogo} className="Home-logo" alt="logo" />
    </>
    );
  }
  
  export default Home;