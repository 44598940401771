import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import axios from 'axios';
import { ListGroup, Row, Col } from 'react-bootstrap';
import '../css/Blog.css';

// The component that lists blog titles
const BlogList = () => {
    const pageSize = 10;
    const apiURL = 'https://api.scrump.tech';

    const [posts, setPosts] = useState();
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [pageCount, setPageCount] = useState();

    useEffect(() => {
        axios.get(`${apiURL}/api/blogs?pagination[pageSize]=${pageSize}`)
        .then(response => {
          const numPages = response.data.meta.pagination.pageCount
          setPageCount(numPages);
        });
    }, [BlogList]);

    useEffect(() => {
      if (page && pageCount) {
        if (pageCount > 0) {

          let active = page;
          let items = [];

          for (let number = 1; number <= pageCount; number++) {
            items.push(
              <Pagination.Item onClick={() => setPage(number)} key={number} active={number === active}>
                {number}
              </Pagination.Item>
          )}

          setPagination(items);

        }
      }
    }, [page, pageCount])
  
    useEffect(() => {
      axios.get(`${apiURL}/api/blogs?sort[0]=publishedAt:desc&fields[0]=Title&fields[1]=Author&fields[2]=publishedAt&pagination[pageSize]=${pageSize}&pagination[page]=${page}`)
        .then(response => {
          setPosts(response.data.data);
        });
    }, [page]);
  
    return (
      <>
        <div className="Content">
        <ListGroup className="List" variant="flush">
        {posts ? (posts.map((post, index) => (
          
            <ListGroup.Item key={index} className="blog-list-item">
              <Row>
                <Col xs={8} className="blog-title FuturaBoldOblique tk-futura-pt-bold">
                  <Link className="custom-link" to={`/research/${post.id}`}>{post.attributes.Title}</Link>
                </Col>
                <Col xs={4} className="text-right blog-date">
                  {post.attributes.publishedAt}
                </Col>
              </Row>
              
              </ListGroup.Item>
          
        ))) : (
          <p>Loading...</p>
        )}
        </ListGroup>
        </div>
        {pagination && (
          <>
         <Pagination>{pagination}</Pagination>
          </>
        )}
      </>
    );
  };
  
  // The component that displays the full content of a blog post
  const BlogPost = () => {
    const [post, setPost] = useState(null);
    const { postId } = useParams();
    const apiURL = 'https://api.scrump.tech';

    const navigate = useNavigate();
    const handleBackClick = () => navigate('/research');
  
    useEffect(() => {
      axios.get(`${apiURL}/api/blogs/${postId}`)
        .then(response => {
          setPost(response.data.data);
        });
    }, [postId]);
  
    return (
      <>
        {post ? (
          <div className="AboutContainer">
          <div className="TitleBlock">
            <div className="Title FuturaBoldOblique tk-futura-pt-bold">
              {post.attributes.Title}
            </div>
            </div>
            <div className="Content BlogContent ck-content">
              <div dangerouslySetInnerHTML={{ __html: post.attributes.BlogContent }} />
            </div>
            <Button onClick={handleBackClick} variant="dark">Back</Button>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </>
    );
  };

  export {BlogList, BlogPost};