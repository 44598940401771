import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home.js';
import { BlogList, BlogPost } from './pages/Blog.js';
import About from './pages/About.js';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import logo from './logo-small.svg'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import './css/Home.css';
import './App.css';

const Layout = () => {
  return (
    <>
    <Navbar data-bs-theme="dark" className="BlackNavbar">
        <Container fluid>
          <Link className="RemoveStyle" to={"/"}>
          <Navbar.Brand>
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            <span className="FuturaBoldOblique MainTitle tk-futura-pt-bold">Scrump Technologies</span>
          </Navbar.Brand>
          </Link>
          <Nav className="FuturaBoldOblique tk-futura-pt-bold">
            <Nav.Link as={Link} to="/about">About Us</Nav.Link>
            <Nav.Link as={Link} to="/research">Blog</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container fluid className="Home">
        <Outlet /> {/* The part of the page that changes with each route */}
      </Container>
    </>
  );
};

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
        <Route path="/research" exact element={<BlogList />} />
        <Route path="/research/:postId" exact element={<BlogPost />} />
        <Route path="/about" exact element={<About />} />
        </Route>
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
