import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Triple from '../components/triple'

function About() {
    const [content, setContent] = useState(null);
    const [tripleContent, setTripleContent] = useState(null);
    const apiURL = 'https://api.scrump.tech';

    const navigate = useNavigate();
    const handleBackClick = () => navigate('/');

    useEffect(() => {
    axios.get(`${apiURL}/api/about`)
        .then(response => {
        setContent(response.data.data);
        });
    }, []);

    useEffect(() => {
        axios.get(`${apiURL}/api/about-triple?populate=*`)
            .then(response => {
            setTripleContent(response.data.data);
            });
    }, []);
    
        return (
        <>
            {content ? (
            <div className="AboutContainer">
                <div className="TitleBlock">
                <div className="Title FuturaBoldOblique tk-futura-pt-bold">
                {content.attributes.Title}
                </div>
                {content.attributes.Title2 && (
                    <>
                    <div className="Title FuturaBoldOblique tk-futura-pt-bold">
                    {content.attributes.Title2}
                    </div>
                    </>
                )}
                </div>

                {content.attributes.Headline && (
                    <>
                    <div className="Headline FuturaBold tk-futura-pt">
                    {content.attributes.Headline}
                    </div>
                    </>
                )}

                {tripleContent && (
                    <Triple url={apiURL} content={tripleContent}></Triple>
                )}
                <div className="Content BlogContent ck-content">
                <div dangerouslySetInnerHTML={{ __html: content.attributes.Content }} />
                </div>
                {//<Button onClick={handleBackClick} variant="dark">Back</Button>
                }
            </div>
            ) : (
            <p>Loading...</p>
            )}
      </>
    );
}

export default About