function Triple(props) {
    let content = props.content
    let url = props.url
    return (
        <>
        {content ? (
        <div className="FlexRow Triple">


        <div className="TripleItem">

            <div className="Center">
                    <img className="TripleIcon" src={url + content.attributes.Icon1.data.attributes.url} alt="Icon 1" />
            </div>

            <div className="TripleTitle FuturaBoldOblique tk-futura-pt-bold">
                {content.attributes.Title1}
            </div>

            <div className="Content TripleContent ck-content">
                <div dangerouslySetInnerHTML={{ __html: content.attributes.Content1 }} />
            </div>
            
        </div>

        <div className="TripleItem">

            <div className="Center">
                    <img className="TripleIcon" src={url + content.attributes.Icon2.data.attributes.url} alt="Icon 2" />
            </div>

            <div className="TripleTitle FuturaBoldOblique tk-futura-pt-bold">
                {content.attributes.Title2}
            </div>

            <div className="Content BlogContent ck-content">
                <div dangerouslySetInnerHTML={{ __html: content.attributes.Content2 }} />
            </div>
                
        </div>

        <div className="TripleItem">

            <div className="Center">
                    <img className="TripleIcon" src={url + content.attributes.Icon3.data.attributes.url} alt="Icon 3" />
            </div>

            <div className="TripleTitle FuturaBoldOblique tk-futura-pt-bold">
                {content.attributes.Title3}
            </div>

            <div className="Content BlogContent ck-content">
                <div dangerouslySetInnerHTML={{ __html: content.attributes.Content3 }} />
            </div>
                
            </div>

        </div>
        ) : (
            <p>Loading...</p>
        )}
        </>
    )};

export default Triple